<template>
    <div>
        <v-layout row wrap align-content-space-around>
            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6>
                            <v-icon color="secondary" size="40">mdi-file-document-edit-outline</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right">
                        <h3 class=" headline">{{ applications_status_waiting }}</h3>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Št. vlog v čakanju</div>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6>
                            <v-icon color="secondary" size="40">mdi-file-document-box-check-outline</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right">
                        <h3 class=" headline">{{ permits_status_active }}</h3>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2 ">Št. aktivnih dovolilnic</div>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6>
                            <v-icon color="secondary" size="40">mdi-file-document-box-check-outline</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right">
                        <h3 class=" headline">{{ number_of_permits }}</h3>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Št. vseh dovolilnic</div>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6>
                            <v-icon color="secondary" size="40">mdi-timer-sand</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right">
                        <h3 class=" headline">{{ expiring_permits }}</h3>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Dovolilnice, ki potečejo v naslednjih 7 dneh</div>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <div style="min-height:5px;"></div>
    </div>
</template>

<script>
import { post, get, destroy, API_ENDPOINT, DEMO_API_ENDPOINT } from '@/helpers/api'

export default {
    name: 'dashboard-widgets',
    data: () => ({
        dataInterval: null,
        applications_status_waiting: null,
        permits_status_active: null,
        number_of_permits: null,
        expiring_permits: null

    }),

    computed: {

    },

    methods: {
        getData() {
            var vm = this
            this.$store.commit('SET_PROGRESS', true)
            //window.console.log("### DashboardWidgets@getData")
            return new Promise( (resolve, reject) => {
                get(API_ENDPOINT + 'v1/admin/data')
                .then(response => {
                    //window.console.log(response.data)
                    response.data.forEach(item => {
                        if(item.key === 'applications_status_waiting') {
                            this.applications_status_waiting = item.value
                        }

                        if(item.key === 'permits_status_active') {
                            this.permits_status_active = item.value
                        }

                        if(item.key === 'number_of_permits') {
                            this.number_of_permits = item.value
                        }

                        if(item.key === 'expiring_permits') {
                            this.expiring_permits = item.value
                        }
                    })
        
                })
                .catch(error => {
                    window.console.log(error)
                })
                .finally(() =>{
                    setTimeout(() => {
                        vm.$store.commit('SET_PROGRESS', false)
                    }, 600)
                })
            })
        }
    },

    created() {

    },

    mounted() {
        var vm = this
        this.getData();
        this.dataInterval = setInterval(() => {
            vm.getData();
        }, 30000);
    },

    beforeDestroy() {
        clearInterval(this.dataInterval)
    }
}

</script>