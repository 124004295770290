<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl fluid>
            <dashboard-widgets></dashboard-widgets>
            <fusion-charts boxedLayout="false"></fusion-charts>
            <access-log></access-log>
        </v-container>
    </div>
</template>

<script>
import AccessLog from '@/components/AccessLog.vue'
import DashboardWidgets from '@/components/admin/DashboardWidgets.vue'
import { EventBus } from '@/main.js'
const FusionCharts = () => import('@/components/admin/FusionCharts.vue')

export default {
    name: 'admin-dashboard',
    components: {
        AccessLog,
        DashboardWidgets,
        FusionCharts
    },

    data: () => ({
    }),

    methods: {
        snackbar() {
            this.$store.state.snackbar.display = false
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        var vm = this

        /*const location = this.$router.resolve({ name: 'admin_applications_edit', params: { id: "58afb641-244d-4570-bf18-06a3979b0b3a" } })
        window.console.log(location)
        window.console.log(window.location.origin)
        window.console.log(window.location.origin + location.href)*/
    },

    destroyed() {
    }


}
</script>

<style scoped>
    

</style>