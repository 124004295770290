<template>
    <div>
        <access-log-data-table
        :settings="dataTableSettings"
        :queryParameters="queryParameters"
        ></access-log-data-table>   

        <data-table-toggle-columns
        :dataTableId="dataTableSettings.id"
        :columns="dataTableSettings.headers"
        :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

        ></data-table-toggle-columns>
    </div>
</template>

<script>
import  { Role } from '@/helpers/role'
import { getUserRole } from '@/helpers/utilities'
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
const AccessLogDataTable = () => import('@/components/AccessLogDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    name: 'access-log',
    components: {
        AccessLogDataTable,
        DataTableToggleColumns
    },
    data: () => ({
        dataTableSettings: {
            id: 'dtAccessLog',
            title: 'Dnevnik dogodkov',
            sortColumn: ['created_at'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Naziv uporabnika", value: "user_name", visibility: true},
                { text: "IP naslov", value: "user_ip", visibility: true },
                { text: "Tip uporabnika", value: "user_role_type", visibility: true },
                { text: "Dogodek", value: "event", visibility: true },
                { text: "Predmet", value: "item_type", visibility: true },
                { text: "Datum in čas dogodka", value: "created_at_formatted", visibility: true,  align: "right" },
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'v1/services/access-log',
            editItem: {
                route: null,
                application_route: 'admin_applications_edit',
                permit_route: 'admin_permits_edit',
                user_route: 'admin_users_edit',
                admin_settings_route: 'admin_settings',
                routeParameterValue: 'item_uuid'
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'access_log',
                totalItems : {
                    event: ''
                },
                search: 'access-log-search',
                toggleColumnsId: 'toggle-access-log-list-columns'
            }


        }
    }),

    computed: {
        queryParameters() {
            return {
                startDate: this.startDate,
                endDate: this.endDate
            }
        }
    },

    methods: {
        search() {
            EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
        },
    },

    created() {
        //window.console.log(getUserRole())
    }
}

</script>

<style scoped>

</style>